<template>
  <div class="mini-app">


    <el-tabs v-model="params.adType" @tab-click="onTabs">
      <!-- 首页广告图 -->
      <el-tab-pane label="品牌首页轮播图" name="1" class="mini-item">
        <div class="left">
          <p class="tips">建议尺寸690*388，大小在500kb以内</p>
          <li v-for="(item, index) in imgList" class="img-list" :key="index">
            <el-image
              class="img-item"
              :src="item.adPicUrl"
              :preview-src-list="[item.adPicUrl]"
            >
            </el-image>
            <div class="img-edit">
              <i class="el-icon-edit" @click="onReplace(item)"></i>
              <i class="el-icon-top" @click="onUp(item)" v-if="index != 0"></i>
              <i
                class="el-icon-bottom"
                @click="onDown(item)"
                v-if="index != imgList.length - 1"
              ></i>
              <i class="el-icon-delete" @click="onDel(item)"></i>
            </div>
          </li>
          <div class="upload-btn" @click="onUpload()" >
            <i class="el-icon-plus uploader-icon"></i>
          </div>
        </div>
        <div class="right">
          <img src="@/assets/images/brand/img.png" alt="" />
        </div>
      </el-tab-pane>
      <!-- 首页品牌视频 -->
      <!-- <el-tab-pane label="首页品牌视频" name="2" class="mini-item">
        <div class="left">
          <p class="tips">视频建议尺寸690*388，大小在15MB以内</p>
          <li v-for="(item, index) in videoList" :key="index" class="img-list">
            <video
              class="video"
              :src="item.adPicUrl"
              muted
              autoplay="autoplay"
              loop
              controls
              object-fit="fill"
            ></video>
            <div class="img-edit">
              <i class="el-icon-edit" @click="onReplace(item)"></i>
            </div>
          </li>
          <div class="upload-btn" @click="onUpload()" v-if="!videoList.length">
            <i class="el-icon-plus uploader-icon"></i>
          </div>
        </div>
        <div class="right">
          <img src="@/assets/images/brand/video.png" alt="" />
        </div>
      </el-tab-pane> -->

    </el-tabs>
    <!-- 图片上传组件 -->
    <UpLoad @fileIds="getFileIds" ref="up" :fileType="fileType"></UpLoad>
  </div>
</template>

<script>
import UpLoad from "@/components/upload";
import {
  getImgList,
  getImgEdit,
  getImgChange,
  getImgDel,
} from "@/api/brand/miniApp";

export default {
  components: { UpLoad },
  data() {
    return {
      imgList: [], //图片列表
      videoList: [], //视频列表
      params: {
        adId: "",
        adPic: "",
        adType: "1",
        changeType: "",
      },
    };
  },
  computed: {
    fileType() {
      if (this.params.adType == "1") {
        return 1;
      } else {
        return 6;
      }
    },
  },
  mounted() {
    this.getImgList("1");
    // this.getImgList("2");
  },
  methods: {
    // 【请求】小程序轮播图列表
    getImgList(adType) {
      let data = {
        adType,
      };
      getImgList(data).then((res) => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            if (adType == "1") {
              this.imgList = res.data;
            } else if (adType == "2") {
              this.videoList = res.data;
            }
          }
        }
      });
    },

    // 【请求】上传图文配置
    getImgEdit() {
      let data = this.params;
      getImgEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            this.getImgList(this.params.adType);
          }
        }
      });
    },

    // 【监听】文件上传
    onUpload() {
      this.params = {
        adId: "",
        adPic: "",
        adType: this.params.adType,
        changeType: "",
      };
      this.$refs.up.onUpload();
    },

    // 【监听】图片编辑
    onReplace(row) {
      row.adType = row.adType.toString();
      this.params = JSON.parse(JSON.stringify(row));
      this.$refs.up.onUpload();
    },

    // 【监听】图片上移
    onUp(row) {
      row.changeType = 1;
      row.adType = this.params.adType;
      this.$confirm("你确定要上移吗?", "上移", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        getImgChange(row).then((res) => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.getImgList(this.params.adType);
          }
        });
      });
    },

    // 【监听】图片下移
    onDown(row) {
      row.changeType = 2;
      row.adType = this.params.adType;
      this.$confirm("你确定要下移吗?", "下移", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        getImgChange(row).then((res) => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.getImgList(this.params.adType);
          }
        });
      });
    },

    // 【监听】图片删除
    onDel(row) {
      this.$confirm("你确定要删除吗?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        getImgDel(row).then((res) => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.getImgList(this.params.adType);
          }
        });
      });
    },

    // 【监听】获取上传文件ID集合
    getFileIds(fileIds) {
      this.params.adPic = fileIds;
      this.getImgEdit();
    },

    // 【监听】菜单切换
    onTabs(tab, event) {},
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";

.mini-app {
  padding: 0.15rem;
}

.video {
  width: 6rem;
  height: 3.5rem;
}

.upload-btn {
  border: 1px dashed #409eff;
  border-radius: 6px;
  cursor: pointer;
  width: 4rem;
  height: 1.5rem;
}

.upload-btn:hover {
  border-color: #409eff;
}

.uploader-icon {
  font-size: 0.28rem;
  color: #409eff;
  width: 4rem;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
}

.img-list {
  margin: 0.1rem 0;
  display: flex;
}

.img-edit {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: 0.2rem;
  width: 2.5rem;

  > i {
    font-size: 0.2rem;
    cursor: pointer;
  }
}

.img-edit > i:hover {
  color: #409eff;
}

.img-item {
  width: 345px;
  height: 194px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
}

.mini-item {
  display: flex;
}

.left {
  width: 60%;
}

.tips {
  margin: 0.15rem 0;
  color: #999;
}
</style>