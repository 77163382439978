import request from '@/utils/request'
// 图片列表
export function getImgList(data) {
  return request('post', '/aps/sp/spAdList', data)
}
// 上传图文配置
export function getImgEdit(data) {
  return request('post', '/aps/sp/createOrUpdate', data)
}
// 上传图文配置
export function getImgChange(data) {
  return request('post', '/aps/sp/change', data)
}
// 上传图文配置
export function getImgDel(data) {
  return request('post', '/aps/sp/delete', data)
}
